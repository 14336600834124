body .main__trends .trends__header {
  border-bottom: 1px solid #B5BACB;
}

body .main__trends .trends__paragraph {
  color: #4A596D;
}
body .main__trends .trends__paragraph span,
.hidden-desc span {
  font-weight: 500;
}
body .main__trends .trends__paragraph .higher,
.hidden-desc .higher {
  color: #00B74A;
}
body .main__trends .trends__paragraph .lower,
.hidden-desc .lower {
  color: #F93154;
}

body .main__trends .trends__paragraph .toggler {
  font-weight: 500;
  cursor: pointer;
  transition: color .3s ease-out;
  will-change: color;
}
body .main__trends .trends__paragraph .toggler:hover,
body .main__trends .trends__paragraph .toggler:hover {
  color: #2A59FF
}

body .main__trends .list-trends__link,
body .main__trends .trends-navigation__link {
  border-right: 1px solid #B5BACB;
}

body .main__trends .list-pos__num {
  border: 1px solid #DFE3F0;
}

body .main__trends .list-pos {
  background-color: #EBF2FF;
  border: 1px solid transparent;
}

body .main__trends .list-pos:hover .list-pos__title {
  color: #2A59FF;
}

body .main__trends .list-pos__name {
  color: #454E53;
}

body .main__trends .list-pos__title {
  color: #1E222A;
}

body .main__trends .list-pos__follow span {
  color: #1E222A;
}

body .main__trends .list-pos__follow:hover {
  background-color: #2A59FF;
}

body .main__trends .list-pos__follow:hover svg path {
  fill: #fff;
}

body .main__trends .trends-navigation__title:not(:last-child)::before {
  opacity: 1;
}

body .main__trends .trends-navigation__title:not(:last-child)::after {
  opacity: 0;
}

body .main__trends .trends-navigation__title._current .main-trends__title {
  color: #1E222A;
}

body .main__trends .trends-navigation__spoiler {
  border: 1px solid #B5BACB;
}

body .main__trends .trends-navigation__spoiler::before {
  opacity: 0;
}
body .first-use__wrapper {
  border: 1px solid transparent;
}
body .first-use__wrapper::after {
  opacity: 1;
}
body .first-use__wrapper::before {
  opacity: 0;
}
body .first-use .first-use-article__paragraph {
  color: #4A596D;
}
body .first-use .button-light {
  opacity: 1;
}
body .first-use .button-dark {
  opacity: 0;
}
body .first-use .first-use-article__button {
  border: 1px solid transparent;
}
body .first-use .first-use-article__button::before {
  opacity: 1;
}
body .first-use .first-use-article__button::after {
  opacity: 0;
}
body .first-use .first-use-form__input {
  color: #1E222A;
}

@media (prefers-color-scheme: dark) {
  body .main__trends .trends__header {
    border-bottom: 1px solid #1E222A;
  }

  body .main__trends .trends__paragraph {
    color: #8B98AA;
  }

  body .main__trends .list-trends__link,
  body .main__trends .trends-navigation__link {
    border-right: 1px solid #323642;
  }

  body .main__trends .list-pos {
    background-color: rgba(41, 44, 45, 0.2);
    border: 1px solid rgba(69, 78, 83, 0.1490196078);
  }

  body .main__trends .list-pos:hover .list-pos__title {
    color: #2A59FF;
  }

  body .main__trends .list-pos__name {
    color: #fff;
  }

  body .main__trends .list-pos__title {
    color: #fff;
  }

  body .main__trends .list-pos__follow span {
    color: #fff;
  }

  body .main__trends .list-pos__follow:hover {
    background-color: #2A59FF;
  }

  body .main__trends .list-pos__follow:hover svg path {
    fill: #fff;
  }

  body .main__trends .trends-navigation__title:not(:last-child)::before {
    opacity: 0;
  }

  body .main__trends .trends-navigation__title:not(:last-child)::after {
    opacity: 1;
  }

  body .main__trends .trends-navigation__title._current .main-trends__title {
    color: #fff;
  }

  body .main__trends .trends-navigation__spoiler {
    border: 1px solid #454E53;
  }

  body .main__trends .trends-navigation__spoiler::before {
    opacity: 1;
  }
}
@media (prefers-color-scheme: dark) {
  body .first-use__wrapper {
    border: 1px solid #454E53;
  }
  body .first-use__wrapper::after {
    opacity: 0;
  }
  body .first-use__wrapper::before {
    opacity: 1;
  }
  body .first-use .first-use-article__paragraph {
    color: #8B98AA;
  }
  body .first-use .button-light {
    opacity: 0;
  }
  body .first-use .button-dark {
    opacity: 1;
  }
  body .first-use .first-use-article__button {
    border: 1px solid rgba(69, 78, 83, 0.5019607843);
  }
  body .first-use .first-use-article__button::before {
    opacity: 0;
  }
  body .first-use .first-use-article__button::after {
    opacity: 1;
  }
  body .first-use .first-use-form__input {
    color: #fff;
  }
}

html.dark body .first-use__wrapper {
  border: 1px solid #454E53;
}
html.dark body .first-use__wrapper::after {
  opacity: 0;
}
html.dark body .first-use__wrapper::before {
  opacity: 1;
}
html.dark body .first-use .first-use-article__paragraph {
  color: #8B98AA;
}
html.dark body .first-use .button-light {
  opacity: 0;
}
html.dark body .first-use .button-dark {
  opacity: 1;
}
html.dark body .first-use .first-use-article__button {
  border: 1px solid rgba(69, 78, 83, 0.5019607843);
}
html.dark body .first-use .first-use-article__button::before {
  opacity: 0;
}
html.dark body .first-use .first-use-article__button::after {
  opacity: 1;
}
html.dark body .first-use .first-use-form__input {
  color: #fff;
}

html.dark body .main__trends .trends__header {
  border-bottom: 1px solid #1E222A;
}

html.dark body .main__trends .trends__paragraph {
  color: #8B98AA;
}

html.dark body .main__trends .list-trends__link,
html.dark body .main__trends .trends-navigation__link {
  border-right: 1px solid #323642;
}

html.dark body .main__trends .list-pos__num {
  border: 1px solid rgba(69, 78, 83, 0.1490196078);
}

html.dark body .main__trends .list-pos {
  background-color: rgba(41, 44, 45, 0.2);
  border: 1px solid rgba(69, 78, 83, 0.1490196078);
}

html.dark body .main__trends .list-pos:hover .list-pos__title {
  color: #2A59FF;
}

html.dark body .main__trends .list-pos__name {
  color: #fff;
}

html.dark body .main__trends .list-pos__title {
  color: #fff;
}

html.dark body .main__trends .list-pos__follow span {
  color: #fff;
}

html.dark body .main__trends .list-pos__follow:hover {
  background-color: #2A59FF;
}

html.dark body .main__trends .list-pos__follow:hover span {
  color: #fff;
}

html.dark body .main__trends .list-pos__follow:hover svg path {
  fill: #fff;
}

html.dark body .main__trends .trends-navigation__title:not(:last-child)::before {
  opacity: 0;
}

html.dark body .main__trends .trends-navigation__title:not(:last-child)::after {
  opacity: 1;
}

html.dark body .main__trends .trends-navigation__title._current .main-trends__title {
  color: #fff;
}

html.dark body .main__trends .trends-navigation__spoiler {
  border: 1px solid #454E53;
}

html.dark body .main__trends .trends-navigation__spoiler::before {
  opacity: 1;
}

html.light body .main__trends .trends__header {
  border-bottom: 1px solid #B5BACB;
}

html.light body .main__trends .trends__paragraph {
  color: #4A596D;
}

html.light body .main__trends .list-trends__link,
html.light body .main__trends .trends-navigation__link {
  border-right: 1px solid #B5BACB;
}

html.light body .main__trends .list-pos__num {
  border: 1px solid rgba(69, 78, 83, 0.1490196078);
}

html.light body .main__trends .list-pos__name {
  color: #454E53;
}

html.light body .main__trends .list-pos {
  background-color: #EBF2FF;
  border: 1px solid transparent;
}

html.light body .main__trends .list-pos:hover .list-pos__title {
  color: #2A59FF;
}

html.light body .main__trends .list-pos__title {
  color: #1E222A;
}

html.light body .main__trends .list-pos__follow span {
  color: #1E222A;
}

html.light body .main__trends .list-pos__follow:hover {
  background-color: #2A59FF;
}

html.light body .main__trends .list-pos__follow:hover span {
  color: #fff;
}

html.light body .main__trends .list-pos__follow:hover svg path {
  fill: #fff;
}

html.light body .main__trends .trends-navigation__title:not(:last-child)::before {
  opacity: 1;
}

html.light body .main__trends .trends-navigation__title:not(:last-child)::after {
  opacity: 0;
}

html.light body .main__trends .trends-navigation__title._current .main-trends__title {
  color: #1E222A;
}

html.light body .main__trends .trends-navigation__spoiler {
  border: 1px solid #B5BACB;
}

html.light body .main__trends .trends-navigation__spoiler::before {
  opacity: 0;
}

html.light body .first-use__wrapper {
  border: 1px solid transparent;
}
html.light body .first-use__wrapper::after {
  opacity: 1;
}
html.light body .first-use__wrapper::before {
  opacity: 0;
}
html.light body .first-use .first-use-article__paragraph {
  color: #4A596D;
}
html.light body .first-use .button-light {
  opacity: 1;
}
html.light body .first-use .button-dark {
  opacity: 0;
}
html.light body .first-use .first-use-article__button {
  border: 1px solid transparent;
}
html.light body .first-use .first-use-article__button::before {
  opacity: 1;
}
html.light body .first-use .first-use-article__button::after {
  opacity: 0;
}
html.light body .first-use .first-use-form__input {
  color: #1E222A;
}

.main__trends {
  margin-bottom: 20px;
}
.hidden-desc {
  display: inline-block;
  font-size: 1rem;
  letter-spacing: 0.005rem;
  font-weight: 400;
  margin-bottom: 0;
  line-height: 1.25;
  opacity: .75;
}
.hidden-desc {
  display: none;
  max-width: 85%;
  margin-bottom: 1.5rem;
  line-height: 1.25;
}
.hidden-desc._active {
  font-family: Rubik;
  font-weight: 300;
  font-size: 14px;
  line-height: 138.19%;
  display: inline-block
}

.toggable-content {
  position: absolute;
  left: -9999px;
  top: -9999px;
  z-index: -1;
  opacity: 0;
  visibility: hidden;
  transition: opacity .15s ease-out;
  will-change: opacity;
}
.toggable-content._active {
  position: relative;
  top: 0;
  left: 0;
  opacity: 1;
  visibility: visible;
  z-index: 1;
}
.trends-navigation.toggable-content._active {
  z-index: 2 !important;
}
.toggable-content.select.is-active {
  z-index: 2;
}
.trends-navigation {
  display: none;
  width: 100%;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
@media (max-width: 1200px) {
  .trends-navigation {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}
@media (max-width: 480px) {
  .trends-navigation {
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
  }
}
.trends-navigation__icon {
  width: 28px;
  height: 28px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-radius: 12px;
  background-color: #2A59FF;
  transition: background-color .3s ease-out;
  will-change: background-color;
}
.trends-navigation__icon i {
  font-size: 1rem;
  color: #fff
}
.trends-navigation__icon:hover {
  background-color: #2994FF;
}
.trends-navigation__list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 15px;
}
@media (max-width: 480px) {
  .trends-navigation__list {
    display: none;
  }
}
.trends-navigation__title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 8px;
  position: relative;
  cursor: pointer;
}
.trends-navigation__title:not(:last-child) {
  padding-right: 15px;
}
.trends-navigation__title::before {
  content: "";
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  height: 18px;
  width: 0.5px;
  opacity: 0;
  background-color: #B5BACB;
}
@media (max-width: 480px) {
  .trends-navigation__title::before {
    display: none;
  }
}
.trends-navigation__title::after {
  content: "";
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  height: 18px;
  width: 0.5px;
  opacity: 0;
  background-color: #323642;
}
@media (max-width: 480px) {
  .trends-navigation__title::after {
    display: none;
  }
}
@media (max-width: 480px) {
  .trends-navigation__title {
    display: none;
  }
}
.trends-navigation__links {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 10px;
}
@media (max-width: 480px) {
  .trends-navigation__links {
    margin-left: auto;
  }
}
.trends-navigation__link {
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  padding-right: 10px;
  -webkit-transition: border ease-in-out 0.3s, color ease-in-out 0.3s;
  transition: border ease-in-out 0.3s, color ease-in-out 0.3s;
  color: #4A596D;
  will-change: border, color;
}
.trends-navigation__link:hover,
.trends-navigation__link:active {
  color: #2A59FF;
}
.trends-navigation__arrow {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  transition: transform .3s ease-out;
  will-change: transform;
}
.trends-navigation__arrow:hover,
.trends-navigation__arrow:active {
  transform: scale(1.5)
}
.trends-navigation .main-trends__title {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #8B98AA;
  transition: font-size .3s ease-out;
  will-change: font-size;
}
@media (max-width: 767.98px) {
  .trends-navigation .main-trends__title {
    display: none;
  }
}
.trends-navigation__spoiler {
  display: none;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 25px;
  height: 25px;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 25px;
          flex: 0 0 25px;
  border-radius: 6px;
  background-color: transparent;
  -webkit-transition: background-color ease-in-out 0.3s, border ease-in-out 0.3s;
  transition: background-color ease-in-out 0.3s, border ease-in-out 0.3s;
  position: relative;
}
@media (max-width: 480px) {
  .trends-navigation__spoiler {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .trends-navigation__spoiler i {
    color: #2A59FF;
    -webkit-transition: color ease-in-out 0.3s;
    transition: color ease-in-out 0.3s;
  }
}
.trends-navigation__spoiler i {
  font-size: 5px;
  -webkit-transition: -webkit-transform ease-in-out 0.3s;
  transition: -webkit-transform ease-in-out 0.3s;
  transition: transform ease-in-out 0.3s;
  transition: transform ease-in-out 0.3s, -webkit-transform ease-in-out 0.3s;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.trends-navigation__spoiler::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(191.45deg, #2A2E3A -73.23%, rgba(42, 46, 58, 0) 91.58%);
  border-radius: 4px;
  opacity: 0;
  -webkit-transition: opacity ease-in-out 0.3s;
  transition: opacity ease-in-out 0.3s;
}
.trends-navigation .select {
  display: none;
  position: relative;
  z-index: 20;
  margin-right: 15px;
}
@media (max-width: 480px) {
  .trends-navigation .select {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}
.trends-navigation .select__header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
}
.trends-navigation .select__current {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 8px;
  margin-right: 11px;
}
.trends-navigation .select__current .main-trends__title {
  display: inline;
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
}
.trends-navigation .select__current .trends-navigation__icon {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 28px;
          flex: 0 0 28px;
}
.trends-navigation .select__item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 6px;
  padding: 6px 10px;
}
.trends-navigation .select__item .trends-navigation__icon {
  width: 22px;
  height: 22px;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 22px;
          flex: 0 0 22px;
  border-radius: 6px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.trends-navigation .select__item .trends-navigation__icon img {
  width: 100%;
  max-width: 40%;
}
.trends-navigation .select__body {
  position: absolute;
  top: calc(100% + 6px);
  left: 0;
  padding: 4px 0;
  width: 100%;
  opacity: 0;
  -webkit-transform: translateY(10px);
          transform: translateY(10px);
  pointer-events: none;
  -webkit-transition: opacity ease-in-out 0.3s, -webkit-transform ease-in-out 0.3s;
  transition: opacity ease-in-out 0.3s, -webkit-transform ease-in-out 0.3s;
  transition: opacity ease-in-out 0.3s, transform ease-in-out 0.3s;
  transition: opacity ease-in-out 0.3s, transform ease-in-out 0.3s, -webkit-transform ease-in-out 0.3s;
}
.trends-navigation .select__body .main-trends__title {
  display: inline;
  font-weight: 700;
  font-size: 14px;
  line-height: 23px;
}

.trends-navigation__select.is-active .trends-navigation__spoiler {
  background-color: #2A59FF;
}
.trends-navigation__select.is-active .trends-navigation__spoiler i {
  color: #fff;
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
}
.trends-navigation__select.is-active .trends-navigation__spoiler::before {
  z-index: -1;
}
.trends-navigation__select.is-active .select__body {
  opacity: 1;
  pointer-events: all;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}

@media (max-width: 480px) {
  .trends-navigation__title._current {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}
.trends-navigation__title._current .main-trends__title {
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
}
@media (max-width: 767.98px) {
  .trends-navigation__title._current .main-trends__title {
    display: inline;
  }
}
.trends__container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.trends__header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 16px;
  padding-bottom: 16px;
  -webkit-transition: border ease-in-out 0.3s;
  transition: border ease-in-out 0.3s;
  gap: 16px;
}
@media (max-width: 480px) {
  .trends__header {
    gap: calc(4px + 12 * (100vw - 320px) / 160);
  }
}
.trends__body {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 20px;
}
@media (max-width: 1200px) {
  .trends__body {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}
.trends__title {
  font-weight: 600;
  font-size: 20px;
  line-height: 23px;
}
@media (max-width: 480px) {
  .trends__title {
    font-size: calc(16px + 4 * (100vw - 320px) / 160);
    line-height: calc(19px + 4 * (100vw - 320px) / 160);
  }
}
.trends__paragraph {
  font-family: Rubik;
  font-weight: 300;
  font-size: 14px;
  line-height: 138.19%;
  -webkit-transition: color ease-in-out 0.3s;
  transition: color ease-in-out 0.3s;
}
@media (max-width: 767.98px) {
  .trends__paragraph {
    font-size: calc(12px + 2 * (100vw - 320px) / 447.98);
  }
}

.header-trends__article {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 10px;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}

.trends-switch {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  cursor: pointer;
  gap: 20px;
  margin-top: 1rem;
  -webkit-tap-highlight-color: transparent;
}
@media (max-width: 480px) {
  .trends-switch {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    gap: 4px;
  }
}
.trends-switch__label {
  font-family: Rubik;
  font-weight: 300;
  font-size: 13px;
  line-height: 138.19%;
  color: #8B98AA;
}
.trends-switch__switch {
  pointer-events: none;
  width: 58px;
  border-radius: 44px;
  border: 1px solid #2A59FF;
  padding: 7px;
  background-color: transparent;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-transition: background-color ease-in-out 0.3s;
  transition: background-color ease-in-out 0.3s;
}
.trends-switch__switch span {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-transition: margin ease-in-out 0.3s, -webkit-box-flex ease-in-out 0.3s;
  transition: margin ease-in-out 0.3s, -webkit-box-flex ease-in-out 0.3s;
  transition: flex ease-in-out 0.3s, margin ease-in-out 0.3s;
  transition: flex ease-in-out 0.3s, margin ease-in-out 0.3s, -webkit-box-flex ease-in-out 0.3s, -ms-flex ease-in-out 0.3s;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 0px;
          flex: 0 0 0;
}
.trends-switch__switch img {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.trends-switch._active .trends-switch__switch {
  background-color: #bbc9f6;
}
.dark .trends-switch._active .trends-switch__switch {
  background-color: #272f41;
}
.trends-switch._active .trends-switch__switch span {
  -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
  margin-right: -14px;
}
.trends-switch._active .trends-switch__switch img {
  -webkit-animation: smooth ease 0.3s;
          animation: smooth ease 0.3s;
}

@-webkit-keyframes smooth {
  0% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
  }
  90% {
    -webkit-transform: scaleX(1.2);
            transform: scaleX(1.2);
  }
  100% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
  }
}

@keyframes smooth {
  0% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
  }
  90% {
    -webkit-transform: scaleX(1.2);
            transform: scaleX(1.2);
  }
  100% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
  }
}
.main-trends__title {
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
}

.list-trends {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  max-width: calc(33.3333333333% - 13.3333333333px);
  width: 100%;
}
@media (max-width: 1200px) {
  .list-trends {
    display: none;
    max-width: 100%;
  }
}
.list-trends__top {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
@media (max-width: 1200px) {
  .list-trends__top {
    display: none;
  }
}
.list-trends__title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 8px;
}
.list-trends__icon {
  width: 28px;
  height: 28px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-radius: 12px;
  background-color: #2A59FF;
}
.list-trends__icon i {
  font-size: 1rem;
  color: #fff;
}
.list-trends__links {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 10px;
}
.list-trends__link {
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  padding-right: 10px;
  -webkit-transition: border ease-in-out 0.3s, color ease-in-out 0.3s;
  transition: border ease-in-out 0.3s, color ease-in-out 0.3s;
  color: #4A596D;
}
.list-trends__link:hover {
  color: #2A59FF
}
.list-trends__arrow {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-transition: transform ease-in-out 0.3s;
  transition: transform ease-in-out 0.3s;
}
.list-trends__arrow:hover {
  transform: scale(1.5)
}
.list-trends__list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 12px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 5px;
}

.list-pos {
  z-index: 10;
  border-radius: 4px;
  height: 40px;
  -webkit-transition: border ease-in-out 0.3s, background-color ease-in-out 0.3s;
  transition: border ease-in-out 0.3s, background-color ease-in-out 0.3s;
  position: relative;
  width: 100%;
}
.list-pos__wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 16px;
  padding: 8px;
  position: relative;
}
.list-pos__wrapper::after {
  content: "";
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: linear-gradient(191.45deg, rgba(28, 49, 109, 0) -73.23%, rgba(2, 10, 196, 0.16) 91.58%);
  -webkit-filter: drop-shadow(0px 4px 4px rgba(30, 33, 48, 0.11));
          filter: drop-shadow(0px 4px 4px rgba(30, 33, 48, 0.11));
  border-radius: 4px;
  opacity: 0;
  -webkit-transition: opacity ease-in-out 0.3s;
  transition: opacity ease-in-out 0.3s;
  z-index: -1;
}
.list-pos__wrapper:hover:after {
  opacity: 0.5;
}
.list-pos_account .list-pos__name {
  gap: 7px;
}
.list-pos__num {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-radius: 6px;
  width: 25px;
  height: 25px;
  -webkit-transition: border ease-in-out 0.3s;
  transition: border ease-in-out 0.3s;
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  color: #515764;
  -ms-flex-negative: 0;
      flex-shrink: 0;
}
.list-pos__main-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.list-pos__icon {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  overflow: hidden;
  -ms-flex-negative: 0;
      flex-shrink: 0;
}
.list-pos__icon img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
}
.list-pos__name {
  display: inline;
  font-family: Rubik;
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  -webkit-transition: color ease-in-out 0.3s;
  transition: color ease-in-out 0.3s;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
}
.list-pos__title {
  font-family: Rubik;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  -webkit-transition: color ease-in-out 0.3s;
  transition: color ease-in-out 0.3s;
  white-space: nowrap;
}
.list-pos__slash {
  margin: 0 10px;
}
.list-pos__short-name {
  text-transform: uppercase;
}
.list-pos__rate {
  margin-right: 10px;
}
.list-pos__price {
  margin-left: auto;
  margin-right: 10px;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
}
.list-pos__follow {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 25px;
  padding: 0 10px;
  border-radius: 3px;
  border: 1px solid #2A59FF;
  margin-left: auto;
  background-color: transparent;
  -webkit-transition: background-color ease-in-out 0.3s, color ease-in-out 0.3s;
  transition: background-color ease-in-out 0.3s, color ease-in-out 0.3s;
  gap: 6px;
}
.list-pos__date {
  margin-left: auto;
  margin-right: 9px;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
}

@media (max-width: 1200px) {
  .list-trends._current {
    display: block;
  }
}

.pos-rate {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 7px;
  margin-left: auto;
}
.pos-rate__icon {
  font-size: 1rem;
}
.pos-rate__icon i {
  color: #2A59FF;
  opacity: 0;
  transition: color .3s ease-out, opacity .3s ease-out;
  will-change: color, opacity;
}
.pos-rate__num {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
}

.pos-rate.minus .pos-rate__num {
  color: #B14C6B;
}
.pos-rate.minus i {
  color: #B14C6B;
  opacity: 1;
}

.pos-rate.plus .pos-rate__num {
  color: #71BA57;
}
.pos-rate.plus i {
  color: #71BA57;
  opacity: 1;
}

.follow-button__icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.follow-button__icon i {
  -webkit-transition: color ease-in-out 0.3s;
  transition: color ease-in-out 0.3s;
}
.follow-button span {
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  -webkit-transition: color ease-in-out 0.3s;
  transition: color ease-in-out 0.3s;
}
.first-use__container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.first-use__article {
  padding: 40px 0;
  padding-left: 43px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}
@media (max-width: 1050px) {
  .first-use__article {
    position: relative;
    padding: 26px 38px;
    margin-bottom: -80px;
  }
}
@media (max-width: 767.98px) {
  .first-use__article {
    padding-top: calc(21px + 5 * (100vw - 320px) / 447.98);
    padding-bottom: calc(21px + 5 * (100vw - 320px) / 447.98);
    padding-left: calc(18px + 20 * (100vw - 320px) / 447.98);
    padding-right: calc(18px + 20 * (100vw - 320px) / 447.98);
  }
}
@media (max-width: 567.98px) {
  .first-use__article {
    margin-bottom: -60px;
  }
}
@media (max-width: 480px) {
  .first-use__article {
    margin-bottom: 0;
  }
}
.first-use__preview {
  height: 100%;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 48%;
          flex: 0 0 48%;
  overflow: hidden;
}
@media (max-width: 1250px) {
  .first-use__preview {
    -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
            flex: 1 1 auto;
    min-width: 500px;
  }
}
@media (max-width: 1200px) {
  .first-use__preview {
    min-width: 400px;
  }
}
@media (max-width: 1050px) {
  .first-use__preview {
    max-width: 630px;
    min-width: none;
    margin: 0 auto;
  }
}
@media (max-width: 480px) {
  .first-use__preview {
    display: none;
  }
}
.first-use__preview img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center bottom;
     object-position: center bottom;
  -webkit-transform: translateY(2.3%);
          transform: translateY(2.3%);
  pointer-events: none;
}
.first-use__wrapper {
  border-radius: 4px;
  -webkit-transition: border ease-in-out 0.3s;
  transition: border ease-in-out 0.3s;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 300px;
  position: relative;
}
@media (max-width: 1200px) {
  .first-use__wrapper {
    height: auto;
  }
}
@media (max-width: 1050px) {
  .first-use__wrapper {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}
.first-use__wrapper::after {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(53.56deg, #EBF2FF -9.61%, #F1F1F1 90.49%);
  -webkit-box-shadow: 0px 4px 22px rgba(224, 227, 244, 0.4);
          box-shadow: 0px 4px 22px rgba(224, 227, 244, 0.4);
  border-radius: 4px;
  -webkit-transition: opacity ease-in-out 0.3s;
  transition: opacity ease-in-out 0.3s;
  opacity: 0;
}
.first-use__wrapper::before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(191.45deg, rgba(127, 133, 153, 0.2) -73.23%, rgba(42, 46, 58, 0) 91.58%);
  border-radius: 4px;
  -webkit-transition: opacity ease-in-out 0.3s;
  transition: opacity ease-in-out 0.3s;
  opacity: 0;
}

.first-use-article {
  margin-right: -50px;
}
@media (max-width: 1200px) {
  .first-use-article {
    margin-right: 0px;
  }
}
.first-use-article__up {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  z-index: 40;
  width: 100%;
  gap: 15px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
@media (max-width: 1050px) {
  .first-use-article__up {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
}
.first-use-article__title {
  font-family: "Roboto";
  font-weight: 400;
  font-size: 23px;
  line-height: 27px;
  text-transform: uppercase;
}
@media (max-width: 1050px) {
  .first-use-article__title {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    white-space: nowrap;
    font-size: 28px;
    gap: 6px;
    line-height: 33px;
  }
}
@media (max-width: 767.98px) {
  .first-use-article__title {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    font-size: calc(20px + 8 * (100vw - 320px) / 447.98);
    line-height: calc(23px + 10 * (100vw - 320px) / 447.98);
  }
}
.first-use-article__title span {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 28px;
  line-height: 33px;
  background: linear-gradient(90.68deg, #2994FF 24.69%, #2932FF 106.28%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}
@media (max-width: 767.98px) {
  .first-use-article__title span {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    font-size: calc(24px + 4 * (100vw - 320px) / 447.98);
    line-height: calc(28px + 5 * (100vw - 320px) / 447.98);
  }
}
.first-use-article__buttons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 50px;
  gap: 12px;
  position: relative;
}
@media (max-width: 1050px) {
  .first-use-article__buttons {
    position: absolute;
    width: 100%;
    top: 100%;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    left: 0;
    padding: 0 38px;
  }
}
@media (max-width: 567.98px) {
  .first-use-article__buttons {
    display: none;
  }
}
.first-use-article__button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 155px;
  border-radius: 10px;
  -webkit-transition: border ease-in-out 0.3s;
  transition: border ease-in-out 0.3s;
  -webkit-transition: background-color ease-in-out 0.3s;
  transition: background-color ease-in-out 0.3s;
  position: relative;
  z-index: 10;
}
.first-use-article__button img {
  position: absolute;
  width: 110px;
  -webkit-transition: opacity ease-in-out 0.3s;
  transition: opacity ease-in-out 0.3s;
  margin: 12px 22px;
}
.first-use-article__button.disabled img {
  opacity: .5;
}
.first-use-article__button::before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(53.56deg, #EBF2FF -9.61%, #F1F1F1 90.49%);
  -webkit-box-shadow: 0px 4px 22px rgba(224, 227, 244, 0.4);
          box-shadow: 0px 4px 22px rgba(224, 227, 244, 0.4);
  border-radius: 10px;
  -webkit-transition: opacity ease-in-out 0.3s;
  transition: opacity ease-in-out 0.3s;
  opacity: 0;
}
.first-use-article__button::after {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(191.45deg, rgba(42, 46, 58, 0.5) -73.23%, rgba(42, 46, 58, 0) 91.58%);
  border-radius: 10px;
  -webkit-transition: opacity ease-in-out 0.3s;
  transition: opacity ease-in-out 0.3s;
  opacity: 0;
}
.first-use-article__button:hover {
  background-color: #2A59FF;
}
.first-use-article__button.disabled:hover {
  cursor: default;
  background-color: #ccc;
}
.first-use-article__paragraph {
  -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 20px 0;
  font-family: Rubik;
  font-weight: 300;
  font-size: 14px;
  line-height: 138.19%;
  -webkit-transition: color ease-in-out 0.3s;
  transition: color ease-in-out 0.3s;
}
@media (max-width: 480px) {
  .first-use-article__paragraph {
    text-align: center;
    padding: calc(10px + 10 * (100vw - 320px) / 447.98) 0;
  }
}

.first-use-form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 50px;
  gap: 10px;
}
@media (max-width: 1050px) {
  .first-use-form {
    width: 100%;
  }
}
@media (max-width: 480px) {
  .first-use-form {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    height: auto;
  }
}
.first-use-form__area {
  width: 300px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-radius: 6px;
  padding-left: 6px;
  border: 1px solid #B5BACB;
}
@media (max-width: 1050px) {
  .first-use-form__area {
    -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
            flex: 1 1 auto;
    width: auto;
  }
}
@media (max-width: 480px) {
  .first-use-form__area {
    height: 50px;
  }
}
.first-use-form__icon {
  font-size: 1.5rem;
  color: #2A59FF;
  margin: 0 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.first-use-form__input {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  width: 100%;
  background-color: transparent;
  transition: color ease-in-out 0.3s;
  padding-right: 10px;
  z-index: 1;
}
.first-use-form__button {
  width: 135px;
  border-radius: 6px;
  background-color: #2A59FF;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #fff;
  -webkit-transition: background-color ease-in-out 0.3s;
  transition: background-color ease-in-out 0.3s;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.first-use-form__button:hover {
  background-color: #1039cc;
}
@media (max-width: 480px) {
  .first-use-form__button {
    height: 50px;
    width: 100%;
  }
}

.first-use-form__button.disabled {
  pointer-events: none;
  opacity: .5;
}

.flex-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: #2994FF;
  color: #fff;
  border-radius: .5rem;
  padding: 1rem;
}
.flex-row.success-form {
  padding: .5rem 1rem
}
.flex-row.success-form p {
  margin-bottom: 0
}
.mr-3 {
  margin-right: 1rem;
}
.success-form .checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: #00B74A;
  fill: none;
  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.success-form .checkmark {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: block;
  stroke-width: 2;
  stroke: #fff;
  stroke-miterlimit: 10;
  margin: 2% .5rem;
  box-shadow: inset 0px 0px 0px #00B74A;
  animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
}

.success-form .checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

.articles-area {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  overflow: hidden;
  position: relative;
}

.articles-area .article {
  display: none;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-shrink: 0;
  padding: 1rem 0;
  width: 100%;
}

.articles-area .article.active {
  display: flex;
}

.articles-area .article .image-area {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  flex-shrink: 0;
  width: 5rem;
  height: 5rem;
  border-radius: .5rem;
  margin-right: .5rem;
  overflow: hidden;
}

.articles-area .article .image-area span,
.articles-area .article .image-area img {
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  object-fit: cover;
  object-position: center;
  transition: transform .3s ease-out;
  will-change: transform;
}

.articles-area .article .image-area:hover img,
.articles-area .article .image-area:active img {
  transform: scale(1.05);
}

.articles-area .article .text-area {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.articles-area .article .text-area .category {
  font-size: .85rem;
  font-weight: 600;
  margin-bottom: .25rem;
  text-transform: uppercase;
  opacity: .75;
}

.articles-area .article .text-area .header {
  font-size: 1rem;
  font-weight: 600;
  letter-spacing: 0.005rem;
  line-height: 1.25;
  margin-bottom: .5rem;
  color: #1E222A;
  transition: color .3s ease-out;
  will-change: color;
}

.dark .articles-area .article .text-area .header {
  color: #fff
}

.articles-area .article .text-area .header:hover,
.articles-area .article .text-area .header:active {
  color: #2A59FF
}

.articles-area .article .text-area .labels,
.articles-area .article .text-area .tokens {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  gap: 1rem;
}
.articles-area .article .text-area .tokens {
  gap: .5rem;
}
.articles-area .article .text-area .labels {
  margin-bottom: .5rem;
}
.articles-area .article .text-area .labels .rate,
.articles-area .article .text-area .tokens .token {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0.35rem 0.75rem;
  background: #2A59FF;
  border-radius: 0.25rem;
  color: #fff;
  font-size: .85rem;
}
.articles-area .article .text-area .tokens .token {
  background: #d2dcff;
  color: #2148d1;
}
.articles-area .article .text-area .labels .rate.bearish {
  background: #B14C6B;
}
.articles-area .article .text-area .labels .rate.bullish {
  background: #71BA57;
}
.articles-area .article .text-area .labels .rate i {
  margin-right: .25rem;
}

.articles-area .article .text-area .data-area {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

.articles-area .article .text-area .data-area .data {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-size: .75rem;
  font-weight: 400;
  line-height: 1;
  opacity: .75;
}

.articles-area .article .text-area .data-area .data.divider {
  opacity: .5;
  margin-left: .5rem;
  margin-right: .5rem;
}

.articles-area .article .text-area .data-area .data i {
  margin-right: .25rem;
}

.pagination-dots {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 1rem;
}

.pagination-dots .pagination-dot {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 0.5rem;
  height: 0.5rem;
  background: #BDBDBD;
  border-radius: 50%;
  cursor: pointer;
  transition: background .3s ease-out;
  will-change: background;
}

.pagination-dots .pagination-dot.active {
  background: #2932FF;
}

.pagination-dots .pagination-dot:not(.active):hover,
.pagination-dots .pagination-dot:not(.active):active {
  background: #2932FF;
}

.dark .pagination-dots .pagination-dot {
  background: #5f70a1;
}

.dark .pagination-dots .pagination-dot:not(.active):hover,
.dark .pagination-dots .pagination-dot:not(.active):active {
  background: #5984ff;
}

.pagination-dots .pagination-dot:not(:last-child) {
  margin-right: .5rem;
}

@keyframes stroke {
  100% {
      stroke-dashoffset: 0;
  }
}

@keyframes scale {
  0%, 100% {
      transform: none;
  }
  50% {
      transform: scale3d(1.1, 1.1, 1);
  }
}

@keyframes fill {
  100% {
      box-shadow: inset 0px 0px 0px 30px #7ac142;
  }
}